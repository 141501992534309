<template>
	<el-dialog title="运单修改" top="1vh" width="1060px" :visible.sync="is_show_in_Page">
		<el-form  label-width="100px">
			

			<div class="big_tit" style="margin-top:-15px">装货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input v-model="form.case_addr_full_text" placeholder="装货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="case_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.case_link_man" placeholder="装货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_link_tel" type="number" placeholder="装货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input v-model="form.aim_addr_full_text" placeholder="卸货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="aim_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.aim_link_man" placeholder="卸货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_link_tel" type="number" placeholder="卸货地联系电话" clearable></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">客户/ 承运人 / 司机 / 车辆</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主姓名" style="width:388px">
					<el-input v-model="form.shipper_info_name" :disabled="tord_info.payed_status!=1"></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主手机号" style="width:388px">
					<el-input v-model="form.shipper_info_tel" :disabled="tord_info.payed_status!=1"></el-input>
				</el-form-item>				
				<el-form-item class="el_form_item" label="货主公司名称" style="width:947px;margin-top: 20px;">
					<el-input v-model="form.shipper_company_info_name" :disabled="tord_info.payed_status!=1"></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="承运人信息" style="width:388px">
					<el-input class="el_inner_width" v-model="truck_owner_full_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机车辆" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_driver_full_text" disabled></el-input>
				</el-form-item>
			</div> 
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车牌号" style="width:388px">
					<el-input class="el_inner_width" v-model="truck_plate_num" ></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机手机号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.driver_tel" ></el-input>
				</el-form-item>
			</div> 
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主备注" style="width:388px">
					<el-input class="el_inner_width" v-model="form.mark" ></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="后台操作备注" style="width:388px">
					<el-input class="el_inner_width" v-model="form.admin_mark" ></el-input>
				</el-form-item>
			</div>
			<div class="big_tit">其他</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="结算方式" style="width:239.5px">
					<el-select class="el_inner_width" @change="settlement_type_change" v-model="form.settlement_type" clearable>
						<el-option label="按吨数" value="1"></el-option>
						<el-option label="按方数" value="2"></el-option>
						<el-option label="按趟" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积(方)" style="width:239.5px">
					<el-input class="el_inner_width" type="number" v-model="cargo_volume.num" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="距离(公里)" style="width:239.5px">
					<el-input class="el_inner_width" type="number" v-model="distance.num" :placeholder="distance.is_need_distance_text" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发货重量(吨)" style="width:239.5px">
					<el-input class="el_inner_width"  v-model="cargo_weight_start" :placeholder="cargo_weight1.is_need_weight_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货重量(吨)" style="width:239.5px">
					<el-input class="el_inner_width" v-model="cargo_weight_arrived" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="最终货重(吨)" style="width:239.5px">
					<el-input class="el_inner_width"  v-model="cargo_weight" :placeholder="distance.is_need_distance_text" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物类型" style="width:239.5px">
					<el-select class="el_inner_width" v-model="form.cargo_type" clearable>
						<el-option label="普货" value="普货"></el-option>
						<el-option label="生鲜" value="生鲜"></el-option>
						<el-option label="危化" value="危化"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物名称" style="width:239.5px">
					<el-input class="el_inner_width" v-model="form.cargo_name" placeholder="请输入货物名称" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="是否回程" style="width:239.5px">
					<el-select class="el_inner_width" v-model="form.is_need_return" clearable>
						<el-option label="需要" value="1"></el-option>
						<el-option label="不需要" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:320px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:320px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<!-- <el-form-item class="el_form_item" label="运单备注" style="width:322px">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item> -->
			</div>
			<div class="big_tit">发车日期</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车日期" style="width:488px">
					<el-date-picker 
						v-model="form.start_time"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="到货时间">
				<div class="block">
					<el-date-picker
					v-model="form.arrived_time"
					type="datetime"
					placeholder="选择日期时间">
					</el-date-picker>
				</div>
			</el-form-item>
				<el-form-item class="el_form_item" label="限定当日" style="width:472px;margin-top: 20px;">
					<el-select class="el_inner_width" v-model="form.start_time_is_check">
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>


			<div style="margin:30px 0 0 0;text-align:center">
				<el-button type="primary" @click="sub">提交修改</el-button>
				<el-button type="danger" @click="clear">还原数据</el-button>
			</div>
		</el-form>

		<!-- 发货地选择 -->
		<el-dialog title="发货地选择" custom-class="el-dialog-s" append-to-body top="1vh" width="90%" :visible.sync="case_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="case_addr_map_choose.is_show" 
				:old_data="case_addr_map_choose.old_data" 
				@choosed="case_addr_map_choosed"
			></mapChoose>
		</el-dialog>

		<!-- 卸货地选择 -->
		<el-dialog title="卸货地选择" custom-class="el-dialog-s" append-to-body top="1vh" width="90%" :visible.sync="aim_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="aim_addr_map_choose.is_show" 
				:old_data="aim_addr_map_choose.old_data" 
				@choosed="aim_addr_map_choosed"
			></mapChoose>
		</el-dialog>

	</el-dialog>
</template>
<script>
	import mapChoose from '@/components/map/amap/choose.vue'
	import {mapMutations, mapState} from 'vuex'
	export default {
		components:{
			mapChoose
		},
		props:{
			is_show:Number,//是否显示
			tord_info:Object,//运单详情
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,
				//表单
				form: {
					driver_tel:'',
					mark:'',//货主备注
					admin_mark:'',//后台操作备注
					settlement_type:'',//结算方式
					cargo_type:'',//货物类型
					cargo_name:'',//货物名称
					is_need_return:'',//是否回程
					freight_total:'',//运费
					shipper_info_name:'',
					shipper_info_tel:'',
					shipper_company_info_name:'',
					//发货省市区
					case_prov:{
						name:'',
						code:'',
					},
					case_city:{
						name:'',
						code:'',
					},
					case_county:{
						name:'',
						code:'',
					},
					case_link_man:'',//装货地联系人
					case_link_tel:'',//装货地联系电话
					case_addr:'',//装货地详情
					case_addr_full_text:'',//装货地完整字符串
					case_lng:'',//装货地经度
					case_lat:'',//装货地纬度
					start_time:null,//发车日期
					arrived_time:null,//到货日期
					start_time_is_check:'1',//发车日期是否限定当日

					//卸货省市区
					aim_prov:{
						name:'',
						code:'',
					},
					aim_city:{
						name:'',
						code:'',
					},
					aim_county:{
						name:'',
						code:'',
					},
					aim_link_man:'',//装货地联系人
					aim_link_tel:'',//装货地联系电话
					aim_addr:'',//卸货地详情
					aim_addr_full_text:"",//卸货地完整字符串
					aim_lng:'',//卸货地经度
					aim_lat:'',//卸货地纬度
					
					out_trade_num:'',//上游运单编号
					group1_num:'',//组编号1(用户自定义打组)
					mark:'',//备注				
				},

				//货物重量
				cargo_weight1:{
					num:'',//数值
					is_need_weight:'',//是否必填
					is_need_weight_text:'',//是否必填的placeholder
				},

				cargo_weight_start:'',
				cargo_weight_arrived:'',
				cargo_weight:'',	

				//货物体积
				cargo_volume:{
					num:'',//数值
					is_need_volume:'',//是否必填
					is_need_volume_text:'',//是否必填的placeholder
				},

				//运输距离
				distance:{
					num:'',//数值
					is_need_distance:'',//是否必填
					is_need_distance_text:'',//是否必填的placeholder
				},

				//客户/ 承运人 / 司机 / 车辆
				shipper_full_text:'',
				truck_owner_full_text:'',
				truck_driver_full_text:'',
				//车牌号
				truck_plate_num:'',

				//发货地地图选择
				case_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				},

				//卸货地地图选择
				aim_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开页面
					this.is_show_in_Page=true;
					//高德地图插件初始化
					this.$amap.load({
						plugins:[
							'AMap.Driving',
						]
					}).then((AMap)=>{

						//初始化
						this.amapDriving = new AMap.Driving({
							policy:AMap.DrivingPolicy.LEAST_TIME,//路线规划策略
						})
					})
					//初始化
					this.clear();
				}
			},
		},
		computed:{
			...mapState(['user_info'])
		},
		methods: {
			//计算运输距离
			distance_calc(){

				//发到货地经纬度齐备时计算导航距离
				if(!(this.form.case_lng&&this.form.case_lat&&this.form.aim_lng&&this.form.aim_lat)){
					//没有经纬度
					var that = this
					if(this.form.aim_addr_full_text && this.form.case_addr_full_text){
						//发到货地都已输入 使用地名查询距离
						var start_province = this.form.case_prov.name + this.form.case_city.name + this.form.case_county.name
						var end_province = this.form.aim_prov.name + this.form.aim_city.name + this.form.aim_county.name
						var start = this.form.case_city.name + this.form.case_county.name + this.form.case_addr
						var end = this.form.aim_prov.name + this.form.aim_city.name + this.form.aim_addr
						this.amapDriving.search(
							[{keyword: start_province, city: start },{ keyword: end_province, city: end },],
							function (status, result) {
								if(status=='complete'&&result.info=='OK'){
									that.distance.num=(result.routes[0].distance/1000).toFixed(3)
								}
							}
						)
					}

					return;
				}

				//计算导航距离
				this.amapDriving.search(
					new AMap.LngLat(this.form.case_lng,this.form.case_lat),//起点
					new AMap.LngLat(this.form.aim_lng,this.form.aim_lat),//终点
					(status,result)=>{
						if(status=='complete'&&result.info=='OK'){
							this.form.distance.num=(result.routes[0].distance/1000).toFixed(3)
						}
					}
				)
			},
			//发货地地址选择相关
			case_addr_map_open(){

				// if(this.tord_info.status!=1){
				// 	this.$my.other.msg({
				// 		type:"warning",
				// 		str:'此运单已发车,不能修改发货地址'
				// 	});
				// 	return;
				// }
				
			    this.case_addr_map_choose.is_show=true;
				this.case_addr_map_choose.old_data.prov_code=this.form.case_prov.code
				this.case_addr_map_choose.old_data.city_code=this.form.case_city.code
				this.case_addr_map_choose.old_data.county_code=this.form.case_county.code
				if(!this.form.case_lng &&!this.form.case_lat){
					this.form.case_lng = '116.40969'
				    this.form.case_lat = '39.89945'
				}
				this.case_addr_map_choose.old_data.lng = this.form.case_lng
				this.case_addr_map_choose.old_data.lat = this.form.case_lat
				var case_addr = ''
				if(this.form.case_addr){
					case_addr =this.form.case_addr
					// case_addr = this.form.case_prov.name + this.form.case_city.name + this.form.case_county.name + this.form.case_addr
				}else{
					case_addr = ''
				}
				this.case_addr_map_choose.old_data.addr=case_addr
				this.case_addr_map_choose.old_data.prov_name=this.form.case_prov.name
				this.case_addr_map_choose.old_data.county_name=this.form.case_county.name
				this.case_addr_map_choose.old_data.city_name=this.form.case_city.name
			},
			case_addr_map_choosed(rel){
				this.case_addr_map_choose.is_show=false;
				this.form.case_prov.name=rel.prov_name
				this.form.case_prov.code=rel.prov_code
				this.form.case_city.name=rel.city_name
				this.form.case_city.code=rel.city_code
				this.form.case_county.name=rel.county_name
				this.form.case_county.code=rel.county_code
				this.form.case_addr=rel.addr_info
				this.form.case_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.case_lng=rel.lng
				this.form.case_lat=rel.lat
				this.distance_calc()
			},

			//卸货地地址选择相关
			aim_addr_map_open(){
				
				// if(this.tord_info.status==3){
				// 	this.$my.other.msg({
				// 		type:"warning",
				// 		str:'此运单已到货,不能修改到货地址'
				// 	});
				// 	return;
				// }
				
				this.aim_addr_map_choose.is_show=true;
				this.aim_addr_map_choose.old_data.prov_code=this.form.aim_prov.code
				this.aim_addr_map_choose.old_data.city_code=this.form.aim_city.code
				this.aim_addr_map_choose.old_data.county_code=this.form.aim_county.code
				if(!this.form.case_lng &&!this.form.case_lat){
					this.form.case_lng = '116.40969'
				    this.form.case_lat = '39.89945'
				}
				this.aim_addr_map_choose.old_data.lng = this.form.aim_lng
				this.aim_addr_map_choose.old_data.lat = this.form.aim_lat
				var aim_addr = ''
				if(this.form.aim_addr){
					aim_addr = this.form.aim_addr
					// aim_addr = this.form.aim_prov.name + this.form.aim_city.name + this.form.aim_county.name + this.form.aim_addr
				}else{
					aim_addr = ''
				}
				this.aim_addr_map_choose.old_data.addr=aim_addr
				this.aim_addr_map_choose.old_data.prov_name=this.form.aim_prov.name
				this.aim_addr_map_choose.old_data.county_name=this.form.aim_county.name
				this.aim_addr_map_choose.old_data.city_name=this.form.aim_city.name
			},
			aim_addr_map_choosed(rel){
				this.aim_addr_map_choose.is_show=false;
				this.form.aim_prov.name=rel.prov_name
				this.form.aim_prov.code=rel.prov_code
				this.form.aim_city.name=rel.city_name
				this.form.aim_city.code=rel.city_code
				this.form.aim_county.name=rel.county_name
				this.form.aim_county.code=rel.county_code
				this.form.aim_addr=rel.addr_info
				this.form.aim_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.aim_lng=rel.lng
				this.form.aim_lat=rel.lat
				this.distance_calc()
			},

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'tord_act_edit_by_admin',
					truck_tord_num:this.tord_info.truck_tord_num,
					set:{
						shipper_tel:this.form.shipper_info_tel,
						settlement_type:this.form.settlement_type,
						is_need_return:this.form.is_need_return,
						cargo_type:this.form.cargo_type,
						out_trade_num:this.form.out_trade_num,
						group1_num:this.form.group1_num,
						mark:this.form.mark,
						admin_mark:this.form.admin_mark,
						driver_tel:this.form.driver_tel,
						cargo_weight_start:this.cargo_weight_start,
						cargo_weight_arrived:this.cargo_weight_arrived,
						cargo_weight:this.cargo_weight,
						truck_plate_num:this.truck_plate_num
					}
				};

				//货物重量
				if(this.cargo_weight1.is_need_weight==1){//必填
					if(!(this.$my.check.is_num(this.cargo_weight)&&this.cargo_weight>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填吨数, 请输入货物吨数'
						});
						return;
					}else post_data.set.cargo_weight=this.cargo_weight
				}else if(this.cargo_weight1.is_need_weight==2){//非必填
					if(this.$my.check.is_num(this.cargo_weight)&&this.cargo_weight>0){
						post_data.set.cargo_weight=this.cargo_weight
					}else post_data.set.cargo_weight=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//货物体积
				if(this.cargo_volume.is_need_volume==1){//必填
					if(!(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填体积, 请输入货物体积'
						});
						return;
					}else post_data.set.cargo_volume=this.cargo_volume.num
				}else if(this.cargo_volume.is_need_volume==2){//非必填
					if(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0){
						post_data.set.cargo_volume=this.cargo_volume.num
					}else post_data.set.cargo_volume=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//运输距离
				if(this.distance.is_need_distance==1){//必填
					if(!(this.$my.check.is_num(this.distance.num)&&this.distance.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填运输距离, 请输入运输距离'
						});
						return;
					}else post_data.set.distance=this.distance.num
				}else if(this.distance.is_need_distance==2){//非必填
					if(this.$my.check.is_num(this.distance.num)&&this.distance.num>0){
						post_data.set.distance=this.distance.num
					}else post_data.set.distance=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//货物名称
				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else post_data.set.cargo_name=this.form.cargo_name.trim()

				// //运费
				// if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){
				// 	post_data.set.freight_total=this.form.freight_total
				// }

				//装货地省市县
				if(!(this.form.case_prov.name&&this.form.case_city.name&&this.form.case_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地数据为空,请选择装货地"
					});
					return;
				}else{
					post_data.set.case_prov=this.form.case_prov.name
					post_data.set.case_city=this.form.case_city.name
					post_data.set.case_county=this.form.case_county.name
					post_data.set.case_prov_code=this.form.case_prov.code
					post_data.set.case_city_code=this.form.case_city.code
					post_data.set.case_county_code=this.form.case_county.code
				}
				
				//装货地联系人/联系电话
				if(this.form.case_link_tel && !this.$my.check.is_tel(this.form.case_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地联系电话格式有误"
					});
					return;
				}else{
					post_data.set.case_link_tel=this.form.case_link_tel
					post_data.set.case_link_man=this.form.case_link_man
				}

				//装货地详细地址
				if(this.form.case_addr && this.form.case_addr.trim()){
					post_data.set.case_addr=this.form.case_addr.trim()
				}

				//装货地经纬度
				post_data.set.case_lng=this.form.case_lng
				post_data.set.case_lat=this.form.case_lat

				//发车日期
				if(this.form.start_time){
					let start_time_1=this.form.start_time[0].getTime();
					let start_time_2=this.form.start_time[1].getTime();
					// let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
					// if(start_time_1<now_time){
					// 	this.$my.other.msg({
					// 		type:"warning",
					// 		str:'发车日期起点不能早于当前时间'
					// 	});
					// 	return;
					// }
					post_data.set.start_time_1=(start_time_1/1000).toFixed(0)
					post_data.set.start_time_2=(start_time_2/1000).toFixed(0)
				}
				//到货日期
				if(this.form.arrived_time){

					post_data.set.arrived_time=(this.form.arrived_time/1000).toFixed(0)
				}
				//发车日期是否强制校验
				if(['1','2'].indexOf(this.form.start_time_is_check)==-1){
					this.$my.other.msg({
						type:"warning",
						str:'请确定发车日期是否限定当日'
					});
					return;
				}else{
					post_data.set.start_time_is_check=this.form.start_time_is_check
				}

				//卸货地省市县
				if(!(this.form.aim_prov.name&&this.form.aim_city.name&&this.form.aim_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"到货地数据为空,请选择到货地"
					});
					return;
				}else{
					post_data.set.aim_prov=this.form.aim_prov.name
					post_data.set.aim_city=this.form.aim_city.name
					post_data.set.aim_county=this.form.aim_county.name
					post_data.set.aim_prov_code=this.form.aim_prov.code
					post_data.set.aim_city_code=this.form.aim_city.code
					post_data.set.aim_county_code=this.form.aim_county.code
				}

				//卸货地联系电话
				if(this.form.aim_link_tel && !this.$my.check.is_tel(this.form.aim_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"卸货地联系电话格式有误"
					});
					return;
				}else{
					post_data.set.aim_link_tel=this.form.aim_link_tel
					post_data.set.aim_link_man=this.form.aim_link_man
				}

				if(this.form.aim_addr && this.form.aim_addr.trim()){//卸货地详情
					post_data.set.aim_addr=this.form.aim_addr.trim()
				}

				//卸货地经纬度
				post_data.set.aim_lng=this.form.aim_lng
				post_data.set.aim_lat=this.form.aim_lat

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//关闭弹出层
						this.is_show_in_Page=false;

						//通知
						this.$emit("sub");
					}
				});
			},

			//结算方式发生变化
			settlement_type_change(){
				switch(this.tord_info.settlement_type){
					case '1'://按重量结算
						this.cargo_weight1.is_need_weight=1;
						this.cargo_weight1.is_need_weight_text='必填';
						this.cargo_volume.is_need_volume=2;
						this.cargo_volume.is_need_volume_text='非必填';
						this.distance.is_need_distance=2;
						this.distance.is_need_distance_text='非必填';
						break;
					case '2'://按体积结算
						this.cargo_weight1.is_need_weight=2;
						this.cargo_weight1.is_need_weight_text='非必填';
						this.cargo_volume.is_need_volume=1;
						this.cargo_volume.is_need_volume_text='必填';
						this.distance.is_need_distance=2;
						this.distance.is_need_distance_text='非必填';
						break;
					case '3'://按趟结算
						this.cargo_weight1.is_need_weight=2;
						this.cargo_weight1.is_need_weight_text='非必填';
						this.cargo_volume.is_need_volume=2;
						this.cargo_volume.is_need_volume_text='非必填';
						this.distance.is_need_distance=1;
						this.distance.is_need_distance_text='必填';
						break;
				}
			},

			//清空本页数据
			clear(){
				console.log(this.tord_info)
				//结算方式/货物重量/体积
				this.form.settlement_type=this.tord_info.settlement_type
				this.cargo_weight1.num=this.tord_info.cargo_weight
				this.cargo_volume.num=this.tord_info.cargo_volume
				this.distance.num=this.tord_info.distance
				
				//结算方式发生变化
				this.settlement_type_change()

				//货物名称/是否回程/上游运费 
				this.form.cargo_type=this.tord_info.cargo_type
				this.form.cargo_name=this.tord_info.cargo_name
				this.form.is_need_return=this.tord_info.is_need_return
				this.form.freight_total=this.tord_info.cost_info.freight_total
				this.form.shipper_info_name=this.tord_info.shipper_info.name,
				this.form.shipper_info_tel=this.tord_info.shipper_info.tel,
				this.form.shipper_company_info_name=this.tord_info.shipper_company_info.name,
				
				//装货地省市区
				this.form.case_prov={
					name:this.tord_info.case_prov,
					code:this.tord_info.case_other_obj.prov_code,
				}
				this.form.case_city={
					name:this.tord_info.case_city,
					code:this.tord_info.case_other_obj.city_code,
				}
				this.form.case_county={
					name:this.tord_info.case_county,
					code:this.tord_info.case_other_obj.county_code,
				}

				//装货地联系人
				this.form.case_link_man=this.tord_info.case_link_man
				this.form.case_link_tel=this.tord_info.case_link_tel

				//装货地详情
				this.form.case_addr=this.tord_info.case_other_obj.addr_info
				this.form.case_addr_full_text=`${this.tord_info.case_prov} / ${this.tord_info.case_city} / ${this.tord_info.case_county} / ${this.tord_info.case_other_obj.addr_info}`

				//装货地经纬度
				this.form.case_lng=this.tord_info.case_lng
				this.form.case_lat=this.tord_info.case_lat

				//发车时间
				if(this.tord_info.case_other_obj.start_time_1 && this.tord_info.case_other_obj.start_time_2){
					this.form.start_time=[
						new Date(this.tord_info.case_other_obj.start_time_1*1000),
						new Date(this.tord_info.case_other_obj.start_time_2*1000)
					];
				}
				//到货时间
				if(this.tord_info.arrived_time !=0){
					this.form.arrived_time = new Date(this.tord_info.arrived_time*1000)
				}
				//发车时间强制校验
				if(['1','2'].indexOf(this.tord_info.case_other_obj.start_time_is_check)!=-1){
					this.form.start_time_is_check=this.tord_info.case_other_obj.start_time_is_check
				}

				//卸货地省市县
				this.form.aim_prov={
					name:this.tord_info.aim_prov,
					code:this.tord_info.aim_other_obj.prov_code,
				}
				this.form.aim_city={
					name:this.tord_info.aim_city,
					code:this.tord_info.aim_other_obj.city_code,
				}
				this.form.aim_county={
					name:this.tord_info.aim_county,
					code:this.tord_info.aim_other_obj.county_code,
				}

				//卸货地联系人
				this.form.aim_link_man=this.tord_info.aim_link_man
				this.form.aim_link_tel=this.tord_info.aim_link_tel

				//卸货地详情
				this.form.aim_addr=this.tord_info.aim_other_obj.addr_info
				this.form.aim_addr_full_text=`${this.tord_info.aim_prov} / ${this.tord_info.aim_city} / ${this.tord_info.aim_county} / ${this.tord_info.aim_other_obj.addr_info}`

				//装货地经纬度
				this.form.aim_lng=this.tord_info.aim_lng
				this.form.aim_lat=this.tord_info.aim_lat

				//客户/ 承运人 / 司机 / 车辆
				this.shipper_full_text=`${this.tord_info.shipper_info.name} / ${this.tord_info.shipper_info.tel} / ${this.tord_info.shipper_company_info.name}`
				this.truck_owner_full_text=`${this.tord_info.truck_owner_info.name} / ${this.tord_info.truck_owner_info.tel}`
				this.truck_driver_full_text=`${this.tord_info.driver_info.name} / ${this.tord_info.driver_info.tel} / ${this.tord_info.truck_info.truck_plate_num}(${this.tord_info.truck_info.truck_type_name} / ${this.tord_info.truck_info.truck_type_name2})`

				this.truck_plate_num = this.tord_info.truck_plate_num
				
				//发到货重量
				this.cargo_weight_start = this.tord_info.cargo_weight_start
				this.cargo_weight_arrived = this.tord_info.cargo_weight_arrived
				this.cargo_weight = this.tord_info.cargo_weight

				//其他信息
				this.form.out_trade_num=this.tord_info.out_trade_num
				this.form.group1_num=this.tord_info.group1_num
				this.form.mark=this.tord_info.mark
				this.form.admin_mark=this.tord_info.admin_mark
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}

	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	
	.el_inner_width{
		width:94%;
	}
	
	.el-dialog-s{
		z-index: 11;
	}

</style>