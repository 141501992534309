<template>
	<div style="position:relative;width:100%;height:100%">
		<div id="container"></div>
		<div class="ser_view">
			<div class="item">
				<el-cascader v-model="cities_choosed" placeholder="省市区选择" :options="cities_arr" @change="cities_choose_change" filterable style="width:100%"></el-cascader>
				<div class="btn btn-free-select" @click="map_center" v-if="center_pointer_btn==1">自由选点</div>
				<div class="btn btn-free-select" @click="map_center_cancel" v-if="center_pointer_btn==2">搜索选点</div>
			</div>
			<div class="item" v-if="(!center_pointer_input)">
				<input class="text"  id="tipinput" v-model="addr_choose_input" @input="ser" placeholder="请输入地址" style="width: 314px;">
				<div class="btn" @click="ser">搜索</div>
				<div class="btn" v-if="(!center_pointer_input)" @click="map_sub">确定</div>
			</div>
			<!-- <div class="item">
				<input class="text"  v-model="addr_full_text" placeholder="请输入详细地址" style="width: 314px;">
			</div> -->
			<div class="item" v-if="addr_choose_rel">
				<img class="img" v-if="addr_choose_img" :src="addr_choose_img">
				<div class="text" style="width: 200px;">{{addr_choose_rel}}</div>
				<div class="btn btn_blue" @click="choose">确定</div>
			</div>
		</div>
		<div class="center_pointer" v-if="center_pointer_show"></div><!-- 地图中心点 -->
		
		<div id="panel"></div>
		<div class="addr_choose_list" v-if="addr_choose_list_is_show">
			<div class="addr_choose_ser_item" v-for="(item,index) in addr_choose_list" :key="index" @click="rel_to_map(index)">
				<div class="addr_choose_ser_item_name">{{item.name}}</div>
				<div class="addr_choose_ser_item_addr">{{item.district}}{{item.address}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Loading } from "element-ui";
	export default {
		props:{
			old_data:Object,
		},
		data() {
			return {
				addr_choose_input:'',
				addr_choose_list_is_show:false,
				addr_choose_list:[],
				addr_choose_rel:'',
				addr_choose_obj:{},
				addr_choose_img:'',
				map_obj:{},
				ser_obj:{},
				
				center_pointer_btn:1,
				center_pointer_show:false, //地图中心点标记
				center_pointer_input:false,//地图自由选点 详细地址输入栏

				//三级联动相关
				cities_arr:[],
				cities_choosed:[],
				CodeToCityItem2:[],

				// addr_full_text:'',//详情

				prov_code:'',
				prov:'',
				city:'',
				county:'',
				city_code:'',
				county_code:'',

				timer:null
			};
		},
		created() {
				//加载地图
				// this.$amap.load({
				// 	plugins:[
				// 		'AMap.DistrictSearch',
				// 	]
				// }).then((AMap)=>{

				// 	//
				// 	this.AMap=AMap;

				// 	//地图初始化
				// 	this.map=new AMap.Map("container",{

				// 		resizeEnable: true,

				// 		//初始化地图级别
				// 		zoom:10,
				// 	});

				// })
			this.$amap.load({
				plugins:[
					'AMap.AutoComplete',
					'AMap.PlaceSearch',
					'AMap.DistrictSearch'
				]
			}).then((AMap)=>{

				//初始化
				this.init(AMap);

				
			})
		},
		methods: {

			//提交
			choose(){
				this.resetMapcenter()
				this.$emit('choosed',{
					prov_name:this.prov,
					prov_code:this.prov_code,
					city_name:this.city,
					city_code:this.city_code,
					county_name:this.county,
					county_code:this.county_code,
					addr_info:this.addr_choose_obj.name,
					lng:this.addr_choose_obj.location.lng,
					lat:this.addr_choose_obj.location.lat,
				})
			},
			zoning_query(name,type){
				var that = this
				var geocoder = new  AMap.Geocoder()
				geocoder.getLocation(name, function(status, result) {
					if (status === 'complete' && result.info === 'OK') {
						if(type == "prov"){
						   that.prov_code = result.geocodes[0].adcode
						}else if(type == 'city'){
						   that.city_code = result.geocodes[0].adcode
						}else if(type =='county'){
						   that.county_code = result.geocodes[0].adcode
						}
					}
				})
			},
			//搜索
			rel_to_map(index){
				var that = this
			    // Loading.service(this.options);
				let choosed={...this.addr_choose_list[index]}
				this.addr_choose_obj = choosed
				this.addr_choose_input=choosed.name
				// AMap.plugin(["AMap.PlaceSearch"], function() {
				// 	//构造地点查询类
				// 	var placeSearch = new AMap.PlaceSearch({
				// 		pageSize: 5, // 单页显示结果条数
				// 		pageIndex: 1, // 页码
				// 		city: "010", // 兴趣点城市
				// 		citylimit: true,  //是否强制限制在设置的城市内搜索
				// 		map: that.map, // 展现结果的地图实例
				// 		panel: "panel", // 结果列表将在此容器中进行展示。
				// 		autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
				// 	});
				// 	//关键字查询
				// 	placeSearch.search(choosed.name);
				// 	console.log(placeSearch)
				// 	placeSearch.on("select", select);//注册监听，当选中某条记录时会触发
				// 	function select(e) {
				// 		console.log(e)
				// 		placeSearch.setCity(e.poi.adcode);
				// 		placeSearch.search(e.poi.name);  //关键字查询查询
				// 	}
				// },);
				this.ser_obj.setCity(choosed.adcode);
				this.ser_obj.search(choosed.name)
				this.addr_choose_rel=''
				var addr = choosed.district
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'get_addr_info',
						district:choosed.district,
						name:choosed.name,
						address:choosed.address,
						addr
					},callback:(data)=>{
						if(choosed.district == '湖北省仙桃市'){
							data.county ="仙桃市"
						}
						this.prov = data.prov
						this.city = data.city
						this.county = data.county
						this.zoning_query(data.prov,"prov")
						this.zoning_query(data.city,'city')
						this.zoning_query(data.county,'county')

						this.addr_choose_rel=data.prov+'/'+data.city+'/'+data.county +'/'+choosed.address
						this.addr_choose_list_is_show=false;
					}
				})
			},
			cities_choose_change(){
				//设置地图中心点
				this.map.setCenter(this.CodeToCityItem[this.cities_choosed[2]].center);

				//清空搜索框
				this.addr_choose_input=''
				this.addr_choose_rel='';
				this.addr_choose_list=[];
				this.addr_choose_list_is_show=false;
			},
			ser(){
				var addr = ''
				//校验城市选择结果
				
				if(this.cities_choosed.length==3){
					let prov=this.CodeToCityItem[this.cities_choosed[0]]
					let city=this.CodeToCityItem[this.cities_choosed[1]]
					let county=this.CodeToCityItem[this.cities_choosed[2]]
					var addr = prov+city+county+this.addr_choose_input
				}else{
					addr = this.addr_choose_input
				}
				if(!this.addr_choose_input){
					this.cities_choosed = []
				}
				
				this.addr_choose_rel='';
				this.addr_choose_list=[];
				this.addr_choose_list_is_show=false;
				
				if(!this.addr_choose_input){
					return;
				}
				
				this.autocomplete.setCity()
				this.autocomplete.search(addr,(status, rel)=>{
					if(status=='complete'){
						if(rel.tips.length>0){
							this.addr_choose_list=rel.tips
							this.addr_choose_list_is_show=true;
						}
					}
				})
			},
			map_sub(){//自有选点--确定提交
				// let that=this;
				// this.resetMapcenter();
				// console.log(this.cities_choosed);
				// var center = this.map.getCenter(); //获取当前地图中心位
				// console.log(center);
				// console.log(this.addr_choose_input);
				
				// console.log(this.cities_arr);
				// console.log(this.CodeToCityItem);
				// console.log(this.cities_choosed);
				
				let prov_data=this.CodeToCityItem[this.cities_choosed[0]];
				let city_data=this.CodeToCityItem[this.cities_choosed[1]];
				let county_data=this.CodeToCityItem[this.cities_choosed[2]];
				let center = this.map.getCenter(); //获取当前地图中心位
				let addr_choose_input=this.addr_choose_input
				// console.log(prov);
				// console.log(city);
				// console.log(county);
				// console.log(center.lat);
				// console.log(center.lng);
				// console.log(this.addr_choose_input);
				
				
				// this.resetMapcenter()
				this.$emit('choosed',{
					prov_name:prov_data.name,
					prov_code:prov_data.adcode,
					city_name:city_data.name,
					city_code:city_data.adcode,
					county_name:county_data.name,
					county_code:county_data.adcode,
					addr_info:addr_choose_input,
					lng:center.lng,
					lat:center.lat,
				})
				
				
				
			},
			map_center_cancel(){	//取消自由选点
			    this.addr_choose_input = ''
				this.center_pointer_btn=1;		//显示自由选点按钮
				this.center_pointer_show=false;	//显示地图中心点--蓝色倒三角
				this.center_pointer_input=false;	//自定义详细地址 输入栏
			},
			map_center(){
				this.addr_choose_input = ''
				let that=this;
				this.center_pointer_btn=2;		//显示搜索选点按钮
				this.center_pointer_show=true;	//显示地图中心点--蓝色倒三角
				// this.center_pointer_input=true;	//自定义详细地址 输入栏
				//获取中心点
				// that.resetMapcenter();
				
				// this.map.on('moveend', function(){
				// 	that.resetMapcenter();
				// });
				// this.map.on('zoomend', function(){
				// 	that.resetMapcenter();
				// });
				
				// var marker = this.map.Marker({
				// 	icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
				// 	position: [116.406315,39.908775],
				// 	offset: new AMap.Pixel(-13, -30)
				// });
				// marker.setMap(map);
			},
			resetMapcenter(){
				
				if(this.center_pointer_show&&this.center_pointer_input){
					var zoom = this.map.getZoom(); //获取当前地图级别
					var center = this.map.getCenter(); //获取当前地图中心位
					this.addr_choose_obj={
						name:this.addr_choose_input,
						location:{
							lng:center.lng,
							lat:center.lat,
						}
					};
				}
			},
			
			
			//初始化
			init(AMap){


				//省市县搜索对象
				new AMap.DistrictSearch({
					
					/*
					关键字对应的行政区级别或商圈，可选值：
					country：国家
					province：省/直辖市
					city：市
					district：区/县
					biz_area：商圈
					*/
					level: 'country',

					//是否显示商圈
					showbiz: false,

					/*
					是否返回行政区边界坐标点
					默认值：base，不返回行政区边界坐标点
					取值：all，返回完整行政区边界坐标点
					*/
					extensions:'base',

					//显示下级行政区级数（行政区级别包括：国家、省/直辖市、市、区/县4个级别），商圈为区/县下一级 可选值：0、1、2、3
					subdistrict:'3'

				}).search('中华人民共和国',(status,result)=>{
					if(status!='complete'){

						return
					}

					let cities_arr=[];
					let CodeToCityItem={};

					var city_transit=function(city_item){
						CodeToCityItem[city_item.name]={
							name:city_item.name,
							adcode:city_item.name,
							center:city_item.center,
							code:city_item.adcode,
						}
						let city_item_buf={
							label:city_item.name,
							value:city_item.name,
							code:city_item.adcode,
						};
						if(city_item.districtList){//不是最后一层
							city_item_buf.children=[]
							for(var city_item2 of city_item.districtList){
								city_item_buf.children.push(city_transit(city_item2))
							}
						}
						return city_item_buf;
					};
					for(var city_item of result.districtList[0].districtList){
						cities_arr.push(city_transit(city_item))
					}
					//保存省份列表
					this.cities_arr=cities_arr
					this.CodeToCityItem=CodeToCityItem
					//处理传入的默认值
					if(this.old_data.prov_code&&this.old_data.city_code&&this.old_data.county_code){
											console.log(this.old_data,999)
						this.cities_choosed=[
							this.old_data.prov_name,
							this.old_data.city_name,
							this.old_data.county_name
						];
						if(this.old_data.addr){
							this.addr_choose_input=this.old_data.addr
						}else{
							this.addr_choose_input=this.old_data.county
						}

						this.ser()
					}
				});
				
				var lat = this.old_data.lat
				var lng = this.old_data.lng
				// if(this.old_data.lat &&this.old_data.lng){
				// 	lat = this.old_data.lat
				// 	lng = this.old_data.lng
				// }
				//地图初始化
				this.map = new AMap.Map("container",{

					resizeEnable: true,

					//初始化地图级别

					center: [lng, lat],//中心点坐标
					zoom:10,
				});
				 var marker = new AMap.Marker({
					position:[lng, lat]//位置
				})
				this.map.add(marker);//添加到地图
				//自动完成初始化
				this.autocomplete= new AMap.AutoComplete()
				//搜索初始化
				this.ser_obj = new AMap.PlaceSearch({
					map:this.map,
					citylimit: true,
					autoFitView: true 
				})
				var that = this
				//搜索结果添加事件
				AMap.Event.addListener(this.ser_obj,'markerClick',(event)=>{
					this.prov=event.data.pname
					this.city=event.data.cityname
					this.county=event.data.adname
					this.zoning_query(event.data.pname,"prov")
					this.zoning_query(event.data.cityname,'city')
					this.zoning_query(event.data.adname,'county')
					this.addr_choose_list_is_show=false;
					this.addr_choose_rel=event.data.pname+'/'+event.data.cityname+'/'+event.data.adname+'/'+event.data.address
					this.cities_choosed[0] = event.data.pcode
	                this.cities_choosed[2] = event.data.adcode
					//市区代码返回有bug 使用高德ipi重新查询市区代码
					var geocoder = new  AMap.Geocoder()
					geocoder.getLocation(event.data.cityname, function(status, result) {
						if (status === 'complete' && result.info === 'OK') {
							that.cities_choosed[1] = result.geocodes[0].adcode
						   // result中对应详细地理坐标信息
						}
					})
					this.addr_choose_obj=event.data
					if(event.data.photos.length>0){
						this.addr_choose_img=event.data.photos[0].url
					}
				})
			},
		},
		destroyed(){
			clearInterval(this.timer);
		}
	};
</script>
<style scoped>

	#container{
		width:100%;
		height:100%;
	}
    #panel {
        position: absolute;
        background-color: white;
        max-height: 90%;
        overflow-y: auto;
        top: 10px;
        right: 10px;
        width: 280px;
    }
	.ser_view{
		position: absolute;
		left: 20px;
		top: 20px;
		border-radius: 4px;
		box-shadow: 2px 2px 5px #777;
		background-color: #fff;
	}
	.item{
		display:flex;
		justify-content: space-between;
		margin: 10px 15px;
	}
	.text{
		line-height: 36px;
		font-size: 15px;
		padding: 0 10px;
		color: #666;
		border: 1px solid #ccc;
	}
	.city_select{
		font-size: 14px;
		padding: 0 3px;
		color: #666;
		height: 38px;
		border: 1px solid #ccc;
	}
	.btn{
		margin-left: 7px;
		font-size: 14px;
		line-height: 38px;
		width: 60px;
		height: 38px;
		text-align: center;
		cursor: pointer;
		color:#fff;
		background-color: #67c23a;
	}
	.img{
		width:114px;
		height:114px;
	}
	.btn_blue{
		background-color: #409eff;
	}
	.btn-free-select{
		width:73px;
		background-color: #409eff;
	}

	.addr_choose_list{
		position:absolute;
		width:433px;
		max-height: 500px;
		overflow-y:auto;
		top: 120px;
		left: 20px;
		box-shadow: 2px 2px 5px #777;
		background-color: #eee;
	}
	.addr_choose_ser_item{
		background-color: #fff;
		cursor:pointer;
		margin: 10px;
	}
	.addr_choose_ser_item:hover{
		background-color: #409eff;
		color:#fff;
	}
	.addr_choose_ser_item_name{
		padding: 10px;
		border-bottom: 1px solid #ccc;
	}
	.addr_choose_ser_item_addr{
		padding: 10px 10px 15px 10px;
		color: #ccc;
	}
	.center_pointer{
		position:absolute;
		width:0;
		height:0;
		border:15px solid transparent;
		border-top-color:#409eff;
		/* background-color: #409eff; */
		left:0;bottom:0;top:0;right:0;
		margin:auto;
	}
</style>